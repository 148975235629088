/* fix the learn more button layout */
.product-brief__button-quickshop,
.product-brief__button-quickshop--close {
  line-height: 23px;
}
/* font style */
@font-face {
  font-family: 'Conv_OptimaEL-Text';
  src: url('#{$netstorage-font-path}OptimaDisplay/OptimaDisplay-Roman.woff2') format('woff2');
  font-display: swap;
  font-weight: normal;
  font-style: normal;
}
/* set the font for MPP page */
.mpp__header,
.headline--tertiary {
  font-family: 'Optima Display Roman' !important;
}

.header__headline span {
  font-family: 'Optima Display Roman' !important;
}
